import React from 'react';

import scss from "./TopMottos.module.scss";

import Motto from "../../components/Motto/Motto";

import i001 from "../../images/treasures/min/001.webp";
import i005 from "../../images/treasures/min/005.webp";
import i011 from "../../images/treasures/min/011.webp";
import logo from "../../images/logo.webp";

const TopMottos = () => {
    return (
        <section className={ scss.mottos }>
            <div className="inner">
                <Motto src={ i005 } title="農業の現状"
                       description="「見た目」が悪いだけで「おいしさ」の変わらない「規格外農作物」の大量廃棄。
                                    新型コロナウィルス蔓延禍、外食産業の需要が激減したことで余ってしまった大量の「過剰在庫」の廃棄。
                                    そして、異常気象によって育ちすぎ「値崩れ」した農作物の廃棄という、３つの廃棄問題があります。"
                />
                
                <Motto src={ i001 } title="現状の問題点"
                       description="売れない規格外品、新型コロナウィルス感染症、そして気候変動の影響で廃棄される農作物。
                                    その影響で現在、多くの農家さんが経営困難に陥っています。
                                    そして、農作物の大量廃棄は日本で年間６１２万トンにも上る「食品ロス」を深刻化させています。"
                />
                
                
                <Motto src={ logo } title="<span class='farmers'>FARMER'S</span>とは"
                       description="規格外農作物や過剰在庫のような、廃棄されてしまう 「訳あり農作物」に特化したオンラインファーマーズマーケットです。
                                    全国の農家さんと消費者が「訳あり農作物」を直接お取引できるマーケットプレイスを提供し、農家さん、消費者、環境の３方に貢献していきます。
                                    <span class='farmers'>FARMER'S</span>を通して農家さんの収入UPを支援し、消費者へお得なマーケットを提供します。
                                    そしてSDGsの目標に掲げられる「食品ロス削減」への貢献していきます。"
                />
                
                
                <Motto src={ i011 } title="<span class='farmers'>FARMER'S</span>の理念"
                       description="訳あり農作物であっても農家さんが愛情を込めて育てたものに変わりありません。
                                    その「廃棄」を「消費」に変え、食品ロスのない未来を創ります。
                                     そして、農作物が「見た目」ではなく「美味しさ」で評価される世の中を目指します。"
                />
            </div>
        </section>
    )
};

export default TopMottos;
