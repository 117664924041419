import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import scss from './About.module.scss';

import { animation } from "../../App";

const About = () => {

    useEffect(() => {
        animation();

        window.addEventListener("scroll", animation);
        return () => {
            window.removeEventListener("scroll", animation);
        };
    })

    document.title = '会社概要 | 株式会社FARMER\'S';

    return (
        <main>
            <h2 className="title">About</h2>
            <section className={ scss.about }>
                <div className="inner-medium">
                    <ul>
                        <li>
                            <span>会社名</span>
                            <p>株式会社FARMER'S</p>
                        </li>
                        <li>
                            <span>代表者名</span>
                            <p>代表取締役CEO 中川史明<br />特別顧問 新美景司</p>
                        </li>
                        <li>
                            <span>主な事業内容</span>
                            <p>EC（電子商取引）サイトの企画、制作、運営及び管理並びに通信販売業</p>
                        </li>
                        <li>
                            <span>資本金</span>
                            <p>9,990,000円</p>
                        </li>
                        <li>
                            <span>所在地</span>
                            <p>山形県尾花沢市上町4丁目10-23 201号室</p>
                        </li>
                        <li>
                            <span>連絡先</span>
                            <p><Link to="/contact" className="link">こちら</Link>よりお願いいたします</p>
                        </li>
                    </ul>
                </div>
            </section>
        </main>
    )
}

export default About;
