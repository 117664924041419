import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import HeaderLogo from '../../images/hedaer_logo.webp';

import scss from './Header.module.scss';

const Header = () => {

    useEffect(() => {
        header_shadow();

        window.addEventListener("scroll", header_shadow);
        return () => {
            window.removeEventListener("scroll", header_shadow);
        };
    });

    return (
        <header className={ scss.header }>
            <nav className="inner">
                <h1 className="farmers">
                    <Link to="/" onClick={close_menu}>
                        <img src={ HeaderLogo } alt="FARMER'S" width="512" height="77" />
                    </Link>
                </h1>

                <input type="checkbox" id="header-menu" />

                <label htmlFor="header-menu">MENU</label>

                <ul>
                    <li><Link to={"/"} onClick={close_menu}>TOP</Link></li>
                    <li><Link to={"/news"} onClick={close_menu}>ニュース</Link></li>
                    <li><Link to={"/members"} onClick={close_menu}>メンバー</Link></li>
                    <li><Link to={"/about"} onClick={close_menu}>会社概要</Link></li>
                    <li><Link to={"/contact"} onClick={close_menu}>お問い合わせ</Link></li>
                </ul>
            </nav>
        </header>
    );
}

const close_menu = () => {
    (document.getElementById('header-menu')! as HTMLInputElement).checked = false;
}

const header_shadow = () => {
    let scroll = window.pageYOffset;
    let target = document.getElementsByTagName('header')[0];

    if (scroll > 0)
        target.classList.add(scss.shadow);

    else
        target.classList.remove(scss.shadow);
}

export default Header;
