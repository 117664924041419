import React from 'react';

import scss from './Motto.module.scss';

interface MottoTypes {
    src: string,
    title: string,
    description: string
}

const Motto = (props: MottoTypes) => {
    return (
        <section className={ `${scss.motto} animation appear` } data-class={`view ${ scss.view }`}>
            <div className={ scss.left }>
                <img src={ props.src } alt={ props.title } />
                <img src={ props.src } alt={ props.title } />
            </div>
            <div className={ scss.right }>
                <h3><span dangerouslySetInnerHTML={{__html: props.title}} /></h3>
                <p dangerouslySetInnerHTML={{__html: props.description}} />
            </div>
        </section>
    );
}

export default Motto;
