import React from "react";
import scss from "./TopView.module.scss";
import i001 from "../../images/treasures/min/001.webp";
import i002 from "../../images/treasures/min/002.webp";
import i003 from "../../images/treasures/min/003.webp";

const TopView = () => {
    return (
        <section id="slideshow" className={ scss.slideshow }>
            <div className={ scss.text + ' inner' }>
                <div>
                    <h2>
                        <span className="animation view-animation" data-class="view">
                            <span>廃棄を消費に変える</span>
                        </span>
                    </h2>
                </div>
                <div>
                    <h3>
                        <span className="animation view-animation" data-class="view">
                            <span>「見た目」ではなく「おいしさ」で評価される世界へ</span>
                        </span>
                    </h3>
                </div>

                <div className={ scss.link }>
                    <a
                        href="https://veebo.farmers-japan.com/"
                        target="_blank" rel="noopener noreferrer"
                        className="button is-theme is-rounded"
                    >
                        「veebo」販売中！
                    </a>
                </div>
            </div>

            <nav>
                <div className={ scss.links + ' inner' }>
                    <ul>
                        <li><a href="https://twitter.com/FARMERS_JAPAN" target="_blank" rel="noopener noreferrer">Twitter</a></li>
                        <li><a href="https://www.instagram.com/farmers_jp/" target="_blank" rel="noopener noreferrer">Instagram</a></li>
                    </ul>
                </div>
            </nav>

            <div className={ scss.backgroundImages }>
                <img src={ i001 } alt="トラクターを用いた廃棄" />
                <img src={ i002 } alt="商品にならなかった姉崎大根" />
                <img src={ i003 } alt="大量の規格外のさくらんぼ" />
            </div>

            <div className={ scss.scrollDown }>
                <span /><span /><span />
            </div>
        </section>
    )
};

export default TopView;
