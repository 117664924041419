import React, { useEffect } from "react";
import { animation } from "../../App";

import scss from './Contact.module.scss';

const Contact = () => {

    document.title = 'お問い合わせ | 株式会社FARMER\'S';

    useEffect(() => {
        animation();

        window.addEventListener("scroll", animation);
        return () => {
            window.removeEventListener("scroll", animation);
        };
    })

    return (
        <main>
            <h2 className="title">Contact</h2>
            <section className={ scss.contact }>
                <div className="inner-medium">
                    お問い合わせは現在、メールでのみ承っております。<br />
                    <a className="link" href="mailto:farmersjpn@gmail.com">farmersjpn@gmail.com</a>
                </div>
            </section>
        </main>
    );
}

export default Contact;