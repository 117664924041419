import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import { news } from "../../data/news";
import News from "../../components/News";

import scss from "./TopNews.module.scss";

const TopNews = () => {

    let array_news: ReactElement[] = [];
    news().slice(0, 5).forEach((content, i) => {
        array_news.push(<News content={content} key={i} />);
    });

    return (
        <section className={`${scss.new_infos} card animation appear` } data-class="view">
            <div className="card-content">
                <h4>お知らせ</h4>
                <div className={ scss.new_info }>{ array_news.map((content) => content) }</div>
                <div className={ scss.more }>
                    <Link to="/news" className="link">全て見る</Link>
                </div>
            </div>
        </section>
    )
};

export default TopNews;
