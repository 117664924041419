import React, { useEffect } from "react";

import TopMottos from "./TopMottos";
import TopNews from "./TopNews";
import TopView from "./TopView";
import TopInstagram from "./TopInstagram";
import { animation } from "../../App";

const Top = () => {

    document.title = 'TOP | 株式会社FARMER\'S';

    useEffect(() => {
        document.getElementById('slideshow')!.style.height = window.innerHeight + 'px';

        animation();

        window.addEventListener("scroll", animation);
        return () => {
            window.removeEventListener("scroll", animation);
        };
    });

    return (
        <div id="Top">
            <TopView />

            <main>
                <TopMottos />

                <div className="inner">
                    <TopNews />
                    <TopInstagram />
                </div>
            </main>
        </div>
    );
}

export default Top;
