import React from "react";

const NotFound = () => {

    document.title = 'NotFound | 株式会社FARMER\'S';

    return (
        <main id="NotFound">
            <h2 className="title">HTTP 404 - NotFound</h2>
            <div className="inner-small">
                <section className="card">
                    <div className="card-content has-text-centered">
                        ページが見つかりません。<br />
                        Request Page is NotFound.
                    </div>
                </section>
            </div>
        </main>
    );
}

export default NotFound;