import React from 'react';
import { Link } from 'react-router-dom';

import scss from './Footer.module.scss';

const Footer = () => {

    return (
        <footer className={ scss.footer }>
            <div className="inner">
                <div className={ scss.content }>
                    <div className={ scss.left }>
                        <h1>株式会社<span className="farmers">FARMER'S</span></h1>
                        <h2>廃棄を消費に変える</h2>
                    </div>
                    <div className={ scss.right }>
                        <div>
                            <ul className={ scss.site_links }>
                                <li><Link to="/">TOP</Link></li>
                                <li><Link to="/news">NEWS</Link></li>
                                <li><Link to="/members">MEMBERS</Link></li>
                                <li><Link to="/about">ABOUT US</Link></li>
                                <li><Link to="/contact">CONTACT US</Link></li>
                            </ul>
                            <ul className={ scss.site_links }>
                                <li><a href="https://twitter.com/FARMERS_JAPAN" target="_blank" rel="noopener noreferrer">Twitter</a></li>
                                <li><a href="https://www.instagram.com/farmers_jp/" target="_blank" rel="noopener noreferrer">Instagram</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <ul className={ scss.other_links }>
                    <li>(C) 2021 株式会社<span className="farmers">FARMER'S</span></li>
                </ul>
            </div>
        </footer>
    )
}

export default Footer;