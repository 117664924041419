import React, {ReactElement, useEffect} from 'react';

import MemberCard from "../../components/Member/Card/MemberCard";

import scss from './Members.module.scss';
import { members } from '../../data/members';
import { animation } from "../../App";

const Members = () => {

    document.title = 'メンバー | 株式会社FARMER\'S';

    let array_members: ReactElement[] = [];
    Object.keys(members()).forEach((key: string) => {
        let member = members()[key];
        array_members.push(<MemberCard data={member} id={key} key={key} />);
    });

    useEffect(() => {
        animation();

        window.addEventListener("scroll", animation);
        return () => {
            window.removeEventListener("scroll", animation);
        };
    });

    return (
        <main>
            <div className="inner-medium">
                <h2 className="title">MEMBERS</h2>
            </div>
            <div className={ scss.members }>
                <div className="inner-medium">
                    <ul>{ array_members.map((content) => content) }</ul>
                </div>
            </div>
        </main>
    );
};

export default Members;
