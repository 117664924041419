import news_json from './news.json';

export interface NewsInterface {
    url: string,
    date: string,
    text: string
}

export const news = () => {
    let news_array: NewsInterface[] = news_json;
    return news_array;
}