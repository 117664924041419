import React from 'react';
import { NewsInterface } from '../data/news';

const News = (props: { content: NewsInterface }) => {

    return (
        <a href={props.content.url} className="link" target="_blank" rel="noopener noreferrer">
            <span>{props.content.date}</span>
            <div>{props.content.text}</div>
        </a>
    )
}

export default News;