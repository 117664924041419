import React, { useEffect } from 'react';

import scss from './Instagram.module.scss';

interface InstagramData {
    data: {
        id: number,
        caption: string,
        permalink: string,
        media_url: string,
        rating: number
    }
}

const Instagram = (props: InstagramData) => {

    useEffect(() => {
        square();
    });
        
    useEffect(() => {
        lazy_load();
    }, []);

    let datum = props.data;

    return (
        <li className={scss.post}>
            <a href={datum.permalink} target="_blank" rel="noreferrer noopener">
                <img data-src={datum.media_url} alt={datum.caption} className="delay-image" />
                <span>詳細を見る</span>
            </a>
        </li>
    );
};

const square = () => {
    let targets = document.getElementsByClassName(scss.post);
    let width = 0;
    Object.keys(targets).forEach((key) => {
        let target = targets[Number(key)];
        width = width === 0 ? target.clientWidth : width;
        (target as HTMLElement).style.height = width + 'px';
    });
};

const lazy_load = () => {
    let lazyImages = [].slice.call(document.querySelectorAll("img.delay-image"));

    if ("IntersectionObserver" in window) {
        let lazyImageObserver = new IntersectionObserver((entries, observer) => {

            entries.forEach((entry) => {

                if (entry.isIntersecting) {
                    let lazyImage = entry.target as HTMLImageElement;
                    lazyImage.src = lazyImage.dataset.src ?? '';
                    lazyImage.classList.remove("delay-image");
                    lazyImageObserver.unobserve(lazyImage);
                }
            });
        });

        lazyImages.forEach((lazyImage) => {
            lazyImageObserver.observe(lazyImage);
        });
    } else {
        let posts = document.getElementsByClassName("delay-image");
        Object.keys(posts).forEach((key) => {
            let post = posts[Number(key)] as HTMLImageElement;
            let src = post.dataset.src ?? '';
            post.src = src;
        });
    }
}

export default Instagram;
