import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Top from './pages/Top/Top';
import News from './pages/News/News';
import About from './pages/About/About';
import Contact from './pages/Contact/Contact';
import Members from './pages/Members/Members';
import NotFound from './pages/404';

import './App.scss';

import ScrollToTop from './ScrollToTop';

export const App = () => {

    useEffect(() => {
        animation();

        window.addEventListener("scroll", animation);
        return () => {
            window.removeEventListener("scroll", animation);
        };
    });

    window.onscroll = () => animation();
    window.onresize = () => animation();

    return (
        <React.Fragment>
            <Router>
                <ScrollToTop />
                <Header />
                <Switch>
                    <Route path="/"        exact component={Top} />
                    <Route path="/news"    exact component={News} />
                    <Route path="/about"   exact component={About} />
                    <Route path="/contact" exact component={Contact} />
                    <Route path="/members" exact component={Members} />
                    <Route path="/"              component={NotFound} />
                </Switch>
                <Footer />
            </Router>
        </React.Fragment>
    )
}

export const animation = () => {
    let animation_elements = document.getElementsByClassName('animation');

    Object.keys(animation_elements).forEach((key: string) => {

        let element = animation_elements[Number(key)] as HTMLElement;
        let y1 = window.innerHeight;
        let y2 = element.getBoundingClientRect().top;

        if (y1 >= y2) {
            (element.dataset.class ?? '').split(' ').forEach((class_value: string) => {
                element.classList.add(class_value);
            });
        }
    });
}
