import React from 'react';

import scss from './MemberCard.module.scss';
import { MemberInterface } from '../../../data/members';

interface MemberCardInterface {
    data: MemberInterface,
    id: string
}

const MemberCard = (props: MemberCardInterface) => {
    
    let short_histories = props.data.short_histories.join('');

    return (
        <div className={ `${scss.member} animation appear` } data-class="view">
            <img
                src={ require(`./../../../images/members/${ props.data.image }`).default }
                alt={ props.data.name }
                width="300" height="400"
            />
            <div>
                <span>{ props.data.position }</span>
                <h3>{ props.data.name }</h3>
                <p>{ short_histories }</p>
            </div>
        </div>
    );
}

export default MemberCard;
