import members_json from './members.json';

export interface MemberInterface {
    name: string,
    en_name: string,
    position: string,
    histories: string[],
    short_histories: string[],
    introduction: string[],
    image: string
}

interface MembersInterface {
    [key: string]: MemberInterface
}

export const members = () => {
    let members_array: MembersInterface = members_json;
    return members_array;
}