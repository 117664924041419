import React, {ReactElement} from 'react';

import scss from './News.module.scss';

import { news } from "../../data/news";
import NewsComponent from "../../components/News";

const News = () => {
    
    document.title = 'ニュース | 株式会社FARMER\'S';

    let array_news: ReactElement[] = [];
    news().forEach((content, i) => {
        array_news.push(<NewsComponent content={content} key={i} />);
    });

    return (
        <main>
            <h2 className="title">NEWS</h2>
            <section className={ scss.news }>
                <div className="inner">
                    <div className={ scss.list }>{ array_news.map((content) => content) }</div>
                </div>
            </section>
        </main>
    );
}

export default News;
