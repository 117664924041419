import React, { useState, useEffect } from "react";
import axios from "axios";

import scss from "./TopInstagram.module.scss";
import Instagram from "../../components/Instagram/Instagram";

const TopInstagram = () => {

    const [data, setData] = useState([]);

    useEffect(() => {
        axios.get('https://blog.farmers-japan.com/instagram/index.php').then((result) => {
            setData(result.data.data.slice(0, 12));
        });
    }, []);

    return (
        <ul className={ `${scss.instagram} animation appear` } data-class="view">{
            data.map((datum, key) => {
                return <Instagram data={datum} key={key} />;
            })
        }</ul>
    );
};

export default TopInstagram;
